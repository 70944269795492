.services-container {
  position: relative;
  /* background-color: var(--offBackground); */
  background-image: linear-gradient(to left, #bb4848, #7a2727) no-repeat 50px
    50px;
  background-image: linear-gradient(
    to top,
    transparent 80px,
    var(--offBackground) 80px,
    var(--offBackground) 100%
  );
  z-index: 1;

  /* background-position: 20% 20%; */
  /* background-image: url("../../images/home_background.png"); */
}

.bg-mask {
  position: absolute;
  background-color: var(--secondary);
  width: 100vw;
  min-height: 220px;
  z-index: 1;
}

.index {
  position: relative;
  z-index: 20;
}

.head-customize {
  z-index: 20;
  padding: 30px;
}

.serv-wrapper {
  display: flex;
  margin: 20px;
}

.serv-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--trueBlack);
  background-color: var(--secondary);
  font-size: 1.5rem;
  font-weight: 500;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(56, 56, 56, 0.199);
  padding: 20px;
  min-width: 14rem;
  min-height: 10rem;
  max-width: 14rem;
  max-height: 10rem;
}

.serv-description {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  background-color: var(--primary);
  margin-left: 15px;
  padding: 20px;
  text-align: left;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .serv-wrapper {
    flex-direction: column;
  }

  .serv-title {
    max-width: unset;
    min-width: unset;
    max-height: unset;
    min-height: unset;
    width: 100%;
  }

  .serv-description {
    margin-left: 0;
  }
}
