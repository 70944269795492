.home-component {
}

.blue-top {
  background-color: var(--primary);
  min-height: 1.5em;
  background-image: url("../../images/wave.png");
  background-size: cover;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.navbarMain {
  background-color: var(--secondary);
  /* height: 200px; */
  box-shadow: none;
  margin-top: 1.5em;
}
