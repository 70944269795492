.brand-component {
  display: flex;
}

.logo {
  height: 3em;
}

.brand-name {
  color: var(--primary);
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
}
