.modal-customization {
  max-width: unset;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.head-cp {
  padding-top: 20px;
  padding-bottom: 30px;
}
.content-cstm {
  height: 100%;
  border-radius: 0;
}

.tile-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tile {
  height: 10rem;
  width: 15rem;
  margin: 10px;
  border-radius: 15px;
}

.img-fill {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.tile-mask {
  display: flex;
  flex-direction: column;
  background-color: rgba(66, 46, 46, 0.6);
  color: var(--secondary);
  justify-content: flex-end;
  align-items: center;
}

.tile-title {
  margin: 0;
}

.tile-desc {
  /* color: var(--secondaryText); */
  font-weight: 300;
  font-size: 0.85rem;
}

.title-action {
  font-size: 0.85rem;
}
