.footer-container {
  display: flex;
  align-items: center;
  background-color: var(--offBackground);
  background-image: url("../../images/footer_background.png");
  background-repeat: no-repeat;
  background-size: 50vw 100%;
  background-position: bottom;
  margin-top: 50px;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-logo {
  flex: 2;
  padding: 0.5rem;
}

.fooler-img {
  width: 15vw;
  height: auto;
}

.contact-info {
  flex: 4;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}

.connect-head {
  font-size: 1.5rem;
  padding-bottom: 10px;
}

.connect-item {
  display: flex;
  align-items: center;
}

.connect-text {
  padding-left: 5px;
  margin: 0;
  color: var(--secondaryText);
}

.connect-text:hover {
  color: var(--trueBlack);
  cursor: pointer;
}

.social-icon {
  margin-top: 8px;
  cursor: pointer;
}

.icon-style {
  height: 20px;
  margin: 5px;
}

.icon-link {
  color: unset;
}

.icon-link:hover {
  color: unset;
}

.address {
  flex: 4;
  align-self: flex-start;
}
