.landing-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.2em;
  background-image: url("../../images/home_background.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 70vw auto;
  padding-top: 3.5em;
  /* background-color: coral; */
  /* height: calc(100vh - 5.2em); */
}

.home-text {
  flex: 1.5;
  min-width: 240px;
  padding-right: 2em;
  padding-top: 15vh;
}

.home-img {
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.first-line {
  display: flex;
}

.second-line {
  display: flex;
}

.big-text {
  color: var(--trueBlack);
  font-size: 4rem;
  font-weight: 700;
  padding-left: 10px;
  margin: 0;
  max-height: 90px;
  overflow: hidden;
}

.blue {
  color: var(--primary);
}

.samll-text {
  color: var(--secondaryText);
  text-align: left;
  padding-left: 10px;
  padding-top: 14vh;
}

.btn-wrapper {
  padding-top: 2em;
  display: flex;
  padding-left: 10px;
}

.landing-img {
  height: 15em;
  width: 20em;
  border-radius: 10px;
  margin: 20px;
}

@media only screen and (max-width: 992px) {
  .home-text {
    flex: 2.5;
  }

  .big-text {
    font-size: 3rem;
    max-height: 90px;
  }

  .landing-img {
    height: 12em;
    width: 16em;
  }
}

@media only screen and (max-width: 768px) {
  .landing-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 3.2em;
    padding-top: 3.5em;
  }

  .home-text {
    padding-top: 0vh;
  }

  .landing-img {
    height: auto;
    width: 100%;
    border-radius: 10px;
    margin: 20px;
  }

  .home-img {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .first-line {
    justify-content: center;
  }

  .second-line {
    justify-content: center;
  }

  .big-text {
    font-size: min(10vw, 3rem);
    max-height: 90px;
  }

  .samll-text {
    padding-top: 5vh;
  }
}
