.modal-customization {
  max-width: unset;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.content-cstm {
  height: 100%;
  border-radius: 0;
}

.transpatent-bg {
  background-color: rgba(0, 0, 0, 0.75);
}

.header-cstm {
  border: none;
}

.modal-body-cstm {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: inherit;
}

.carousel-img {
  max-height: 60vh !important;
  max-width: 100% !important;
}

.item-wrapper {
  display: flex;
  justify-content: center;
  background-color: transparent;
  width: 100vw;
}
